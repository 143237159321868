import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = React.forwardRef((props, ref) => {
    return <GatsbyLink ref={ref} {...props} />
})

Link.displayName = 'Link'

export default Link
