import React from 'react'

import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { AppBar, Toolbar, useMediaQuery } from '@mui/material'
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'

const Header = ({ open }) => {
    const theme = useTheme()
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))

    const mainHeader = (
        <Toolbar
            sx={{
                paddingLeft: '0 !important',
                paddingRight: '0 !important',
                height: '72px'
            }}
        >
            <HeaderContent />
        </Toolbar>
    )

    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0
    }

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    )
}

Header.propTypes = {
    open: PropTypes.bool
}

export default Header
