import React from 'react'
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import Link from '../../ui-component/Link'
import Logo from './../../ui-component/Logo'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { useTheme } from '@mui/material/styles'

const Footer = () => {
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Box
            component="footer"
            sx={{
                bgcolor: 'white',
                width: '100%',
                margin: '0px auto',
                padding: '32px 16px',
                borderTop: '1px solid',
                borderColor: 'divider'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                [theme.breakpoints.up(0)]: {
                                    flexDirection: 'column'
                                },
                                [theme.breakpoints.up(600)]: {
                                    flexDirection: 'row'
                                },
                                gap: 2
                            }}
                        >
                            <Stack direction="row" alignItems={'center'} spacing={2}>
                                <Logo />
                                <Typography variant="subtitle2" color="grey.600">
                                    © 2024 Greycolab sp. z o.o.
                                </Typography>
                            </Stack>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                }}
                            >
                                <Stack alignItems="center" direction="row" spacing={2}>
                                    <Typography variant="subtitle2" component={Link} to="/terms-and-conditions">
                                        {t('termsAndConditions')}
                                    </Typography>
                                    <Typography variant="subtitle2" component={Link} to="/privacy-policy">
                                        {t('privacyPolicy')}
                                    </Typography>
                                    <Button variant="outlined" color="primary" size="small" component={Link} to="/contact">
                                        {t('contact')}
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["common"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
