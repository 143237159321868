const React = require('react')
const { I18nextProvider } = require('react-i18next')
const Layout = require('./src/components/layout/index').default
const i18n = require('./src/i18n').default
const { default: ThemeCustomization } = require('./src/themes')

exports.wrapPageElement = ({ element }) => {
    if (!element || !element.props || !element.props.children || !element.props.children.props) {
        return element
    }

    const newElement = React.cloneElement(
        element,
        element.props,
        React.cloneElement(
            element.props.children,
            element.props.children.props,
            React.createElement(Layout, undefined, element.props.children.props.children)
        )
    )

    return <I18nextProvider i18n={i18n}>{newElement}</I18nextProvider>
}

exports.wrapRootElement = ({ element }) => {
    return <ThemeCustomization>{element}</ThemeCustomization>
}
