import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Button, ClickAwayListener, Grid, Paper, Popper } from '@mui/material'
import Transitions from '../../../../../components/ui-component/Transitions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { styled } from '@mui/system'
import Link from './../../../../ui-component/Link'

const ActiveButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    color: 'rgb(179, 181, 183)',
    '&.active': {
        color: 'white',
        '&:before': {
            content: '""',
            borderRadius: '50%',
            position: 'absolute',
            width: '6px',
            height: '6px',
            left: '-12px',
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        }
    },
    '&:before': {
        content: '""',
        borderRadius: '50%',
        position: 'absolute',
        width: '6px',
        height: '6px',
        left: '-12px',
        opacity: 0,
        backgroundColor: theme.palette.primary.main,
        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    '&:hover:before': {
        opacity: 1
    }
}))

const ExpandMenu = ({ data, label }) => {
    const theme = useTheme()

    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ActiveButton
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {label}
            </ActiveButton>
            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 250,
                                    padding: 1
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Grid container spacing={1}>
                                        {data.map(item => (
                                            <Grid item xs={12}>
                                                <Button component={Link} to={item.to} fullWidth>
                                                    {item.name}
                                                </Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    )
}

export default ExpandMenu
