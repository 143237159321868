import React from 'react'
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer } from '@mui/material'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { IconInfoSquareRounded, IconPhone } from '@tabler/icons-react'
import LogoSection from '../../LogoSection'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Link from '../../../ui-component/Link'

const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const { t } = useTranslation()

    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setIsOpen(open)
    }

    const iconBackColorOpen = 'grey.300'
    const iconBackColor = 'grey.100'

    const menuItems = [
        { name: t('aboutUs'), icon: <IconPhone />, to: '/about-us' },
        { name: t('pricing'), icon: <IconInfoSquareRounded />, to: '/pricing' }
    ]

    return (
        <>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                <LogoSection />
                <IconButton
                    disableRipple
                    color="secondary"
                    sx={{ color: 'text.primary', bgcolor: isOpen ? iconBackColorOpen : iconBackColor }}
                    aria-label="open profile"
                    aria-haspopup="true"
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon fontSize="small" />
                </IconButton>

                <SwipeableDrawer anchor="top" open={isOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                    <Box sx={{ width: 'auto' }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                        <List>
                            {menuItems.map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton component={Link} to={item.to}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>
            </Stack>
        </>
    )
}

export default MobileMenu

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["common"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
