import React from 'react'
import { Box } from '@mui/material'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
    return (
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Header />
            <Box component="main" sx={{ width: '100%', flexGrow: 1 }}>
                <Box sx={{ height: '72px' }} />
                {children}
            </Box>
            <Footer />
        </Box>
    )
}

export default Layout
