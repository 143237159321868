import React from 'react'
import { Button, Stack } from '@mui/material'
import Language from '../../Language'
import Link from './../../../ui-component/Link'
import ExpandMenu from '../HeaderContent/ExpandMenu'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import LogoSection from './../../LogoSection/index'

const DesktopMenu = () => {
    const { t } = useTranslation()

    const menuItems = [
        { name: t('aboutUs'), to: '/about-us' },
        { name: t('pricing'), to: '/pricing' }
    ]

    return (
        <>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        display: 'flex',
                        height: '100%',
                        gap: 4
                    }}
                >
                    <LogoSection />
                    <Stack direction="row" spacing={1}>
                        {menuItems.map((item, index) =>
                            item.type === 'expand' ? (
                                <ExpandMenu key={index} data={item.menu} label={item.name} />
                            ) : (
                                <Button key={index} component={Link} to={item.to}>
                                    {item.name}
                                </Button>
                            )
                        )}
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Language />
                    <Button variant="contained" component={Link} to={'/contact'}>
                        {t('contact')}
                    </Button>
                </Stack>{' '}
            </Stack>
        </>
    )
}

export default DesktopMenu

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["common", "header"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
