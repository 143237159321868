import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'

const AppBarStyled = styled(AppBar, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
}))

export default AppBarStyled
