import React from 'react'

import { ButtonBase } from '@mui/material'
import Logo from '../../ui-component/Logo'
import Link from '../../ui-component/Link'

const LogoSection = () => {
    return (
        <ButtonBase disableRipple component={Link} to={'/'}>
            <Logo />
        </ButtonBase>
    )
}

export default LogoSection
