import React from 'react'
import { Container, useMediaQuery } from '@mui/material'
import MobileMenu from './../MobileMenu/index'
import DesktopMenu from './../DesktopMenu/index'
import { graphql } from 'gatsby'

const HeaderContent = ({ data }) => {
    console.log(data)
    const matchesXs = useMediaQuery(theme => theme.breakpoints.down('md'))

    return (
        <>
            <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {matchesXs ? <MobileMenu /> : <DesktopMenu />}
            </Container>
        </>
    )
}

export default HeaderContent

export const query = graphql`
    query {
        file(relativePath: { eq: "logo_bridge.png" }) {
            childImageSharp {
                gatsbyImageData(width: 50, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
        }
    }
`
