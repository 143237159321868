import { brown, pink, purple } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'

const themeColors = {
    primary: '#5569ff',
    secondary: '#6E759F',
    success: '#57CA22',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#223354',
    white: '#ffffff',
    primaryAlt: '#000C57'
}

const Theme = colors => {
    const { grey } = colors
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16]
    }
    const contrastText = '#fff'

    return {
        primary: {
            lighter: '#e5e9ef',
            100: '#bcc7d9',
            200: '#91a3bf',
            light: '#6780a6',
            400: '#456595',
            main: '#003366',
            dark: '#15457d',
            700: '#083c72',
            darker: '#003366',
            900: '#00234f',
            contrastText
        },
        secondary: {
            lighter: '#dff2f5',
            100: '#b0e0e6',
            200: '#7cccd7',
            light: '#45b8c8',
            400: '#16abbd',
            main: '#b0e0e6',
            600: '#008fa2',
            dark: '#007b89',
            800: '#006872',
            darker: '#00464a',
            contrastText: greyColors[0]
        },
        error: {
            lighter: '#ffebee',
            light: '#f5979a',
            main: '#FF3333',
            dark: '#de192c',
            darker: '#c30017',
            contrastText
        },
        warning: {
            lighter: '#fff3de',
            light: '#ffcb78',
            main: '#FFA500',
            dark: '#f77900',
            darker: '#e94b00',
            contrastText: greyColors[100]
        },
        info: {
            lighter: '#e4f7fd',
            light: '#6fd3ed',
            main: '#5bc0de',
            dark: '#4b9ab0',
            darker: '#37636b',
            contrastText
        },
        success: {
            lighter: '#e7f6e9',
            light: '#76cd85',
            main: '#28a746',
            dark: '#1a953a',
            darker: '#00641b',
            contrastText
        },
        grey: greyColors,
        pink: {
            main: pink[500]
        },
        purple: {
            main: purple[500]
        },
        brown: {
            main: brown[500]
        },
        alpha: {
            white: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white
            },
            trueWhite: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white
            },
            black: {
                5: alpha(themeColors.black, 0.02),
                10: alpha(themeColors.black, 0.1),
                30: alpha(themeColors.black, 0.3),
                50: alpha(themeColors.black, 0.5),
                70: alpha(themeColors.black, 0.7),
                100: themeColors.black
            }
        }
    }
}

export default Theme
